import Vue from 'vue'
import Vuex from 'vuex'
import moduleRequest from './moduleRequest';
import moduleRequestTypeDetail from './moduleRequestTypeDetail';
import moduleToast from "./moduleToast";
import moduleUser from './moduleUser';

Vue.use(Vuex)

const store = new Vuex.Store({
	modules: {
		toast: moduleToast,
		users: moduleUser,
		requestTypeDetail:moduleRequestTypeDetail,
		requests:moduleRequest		
	}
})

export default store;