import { BaseService } from './../base'
export class StablishmentService extends BaseService {
    static get entity () {
        return 'stablishment'
    }
    static get name () {
        return 'stablishment'
    }
    static async getAll() {
        return this.submit('get', `${process.env.VUE_APP_API_URL}/api/${this.entity}`, this.name);        
    }
}