<template>
  <v-dialog v-model="dialog" max-width="800px" scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="teal"
        style="color: white"
        outlined
        v-bind="attrs"
        v-on="on"
        :disabled="requestForm.files.length == 0"
        @click="mostrarModal"
      >
        <v-icon dark style="margin-right: 0.5em"> mdi-file-multiple </v-icon>
        Adjuntos
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Archivos adjuntos</v-card-title>
      <v-card-text style="height: 100%">
        <v-overlay :value="loading" absolute>
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-data-table
          :headers="headers"
          :items="requestForm.files"
          item-key="created_at"
          class="elevation-2"
          :loading="loading"
          loading-text="Cargando... Favor de esperar"
        >
          <!-- <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Historial de modificaciones</v-toolbar-title>
            </v-toolbar>
          </template> -->
          <template v-slot:[`item.file_name`]="{ item }">
            {{ item.file_name }}
          </template>
          <template v-slot:[`item.file`]="{ item }">
            <v-btn
              color="teal"
              style="color: white"
              outlined
              v-bind="attrs"
              v-on="on"
              @click="() => openFile(item.file)"
            >
              <v-icon dark>
                mdi-eye
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions flat>
        <v-btn @click="dialog = false" color="secondary">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["requestForm"],
  data() {
    return {
      dialog: false,
      loading: false,
      listRegister: [],
      headers: [
        { text: "Nombre", value: "file_name" },
        { text: "Archivo", value: "file" },
      ],
    };
  },
  methods: {
    async mostrarModal() {
      this.dialog = true;
    },
		openFile(file) {
			window.open(file,'_blank');
		}
  },
  //   watch: {
  //     dialog() {
  //       if (this.dialog) {
  //         this.loadData();
  //       }
  //     },
  //   },
};
</script>