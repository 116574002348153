const moduleRequestTypeDetail = {
	namespaced: true,
	state: {
		requestTypeDetailSelected: {id: 0}
	},
	getters: {
		getRequestTypeDetailSelected(state) {
			return state.requestTypeDetailSelected;
		}
	},
	mutations: {
		loadRequestTypeDetailSelected(state, requestTypeDetailSelected) {
			state.requestTypeDetailSelected = requestTypeDetailSelected;
		}
	},	
}

export default moduleRequestTypeDetail;