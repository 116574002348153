<template>
  <v-dialog v-model="dialog" max-width="800px" scrollable>
    <form v-on:submit.prevent="login" ref="form">
      <v-card>
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title>Reingresar</v-toolbar-title>
        </v-toolbar>
        <v-card-text style="height: 100%">
          <v-overlay :value="loading" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-card-text>
            <v-text-field
              v-model="email"
              label="Correo"
              type="text"
              color="#0FCBBF"
              required
              :loading="loading"
            />
            <v-text-field
              v-model="password"
              label="Contraseña"
              type="password"
              color="#0FCBBF"
              required
              :loading="loading"
            />
          </v-card-text>
        </v-card-text>
        <v-card-actions flat>
          <v-btn @click="dialog = false">Cerrar</v-btn>
          <v-btn color="primary" type="submit "
            ><v-icon left small> mdi-content-save </v-icon>ingresar</v-btn
          >
        </v-card-actions>
      </v-card>
    </form>
  </v-dialog>
</template>

<script>
import { eventBus } from "../../main";
import { UserService } from "../../services/models/user";
export default {
  data() {
    return {
      loading: false,
      dialog: false,
      email: null,
      password: null,
    };
  },
  mounted() {
    eventBus.$on("value", () => {
      this.dialog = true;
    });
  },
  methods: {
    async login() {
      this.loading = true;
      let data = {
        email: this.email,
        password: this.password,
        grant_type: "password",
      };
      try {
        let response = await UserService.login(data);
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        location.reload();
        this.loading = false;
        this.dialog = false;
      } catch (error) {
        this.showAlert({
          color: "error",
          text:
            "Usuario y/o contraseña incorrecta",
        });
        console.log(error);
        this.loading = false;
      }
    },
  },
};
</script>