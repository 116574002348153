<template>
  <!-- App.vue -->
  <v-app>
    <v-navigation-drawer v-model="drawer" dark app>
      <v-list dense>
        <v-subheader>Modulos</v-subheader>
        <SideMenuAdministrative v-if="menu_role_id==3"></SideMenuAdministrative>
        <SideMenuAdministrator v-if="menu_role_id==1"></SideMenuAdministrator>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <CloseSession></CloseSession>
        </div>
      </template>
    </v-navigation-drawer>
    <v-app-bar color="#33AFFF" dense app>
      <v-app-bar-nav-icon
        color="white"
        @click="drawer = drawer == true ? false : true"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="white--text">BENEFICIOS</v-toolbar-title>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <relogin></relogin>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <toast />
        <!-- If using vue-router -->
        <notification/>        
        <slot name="contenido"></slot>
      </v-container>
    </v-main>

    <v-footer padless inset app>
      <v-col class="text-center" cols="12">
        {{ new Date().getFullYear() }} —
        <strong>Servicio de Salud Magallanes &copy; </strong>
        Versión {{global}}
      </v-col>
    </v-footer>
  </v-app>
</template>
<script>
import CloseSession from "../components/general/CloseSession";
import Notification from '../components/general/Notification.vue';
import SideMenuAdministrative from "../views/administrative/SideMenu";
import SideMenuAdministrator from "../views/administrator/SideMenu.vue";
import Relogin from '../components/general/Relogin.vue';
import Toast from '../components/customComponents/Toast.vue';

export default {
  components: {
    CloseSession,    
    SideMenuAdministrative,
    SideMenuAdministrator,
    Notification,    
    Relogin,
    Toast,
  },
  data() {
    return {
      drawer: false,
      global:process.env.VUE_APP_GLOBAL_VERSION,
      menu_role_id:JSON.parse(window.localStorage.getItem("user")).role_id
    };
  },
};
</script>