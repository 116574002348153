<template>
  <PlantillaHome>
    <div slot="contenido">
      <v-card elevation="10" class="d-flex pa-4">
        <!-- <v-btn elevation="10" color="primary" fab><v-icon dark> mdi-plus </v-icon></v-btn>         -->        
        <UsersList></UsersList>
      </v-card>
    </div>
  </PlantillaHome>
</template>
<script>
import PlantillaHome from "../../../../templates/PlantillaHome";
import UsersList from "../../../../components/mainteiners/users/UsersList.vue";
export default {
  components: {
    PlantillaHome,
    UsersList,
  },
  data() {
    return {
      
    };
  },
  methods: {},
};
</script>