import { BaseService } from './../base'
export class RequestTypeDetailService extends BaseService {
    static get entity () {
        return 'request_type_detail'
    }
    static get name () {
        return 'request_type_detail'
    }
    static async getAll() {
        return this.submit('get', `${process.env.VUE_APP_API_URL}/api/${this.entity}`, this.name);        
    }
    static async getByRequestType(id){        
        return this.submit('get', `${process.env.VUE_APP_API_URL}/api/${this.entity}/find_by_request_type_id/${id}`, this.name);      
    }
}