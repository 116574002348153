import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import store from "./store/store";

import router from './router';

import VueApexCharts from 'vue-apexcharts'
import moment from 'moment';
Vue.use(VueApexCharts);

Vue.component('apexchart', VueApexCharts);

import './thirdParty';
import { formatRut } from './thirdParty/utils';

export const eventBus = new Vue();

const appVersion = "2.5";
const browserVersion = window.localStorage.getItem("appVersion");

if (appVersion !== browserVersion) {
  window.localStorage.setItem("appVersion", appVersion);
  window.location.reload(true);
}

Vue.mixin({
  methods: {
    getCurrentUserLoggedIn() {
      return JSON.parse(window.localStorage.getItem("user"));
    },
    showAlert(options) {
      eventBus.$emit("showNotification", options);
    },
    showCountdown(number,total){
      eventBus.$emit('showCountdown',number,total)
    },
    hideCountdown(){
      eventBus.$emit('hideCountdown');
    },
    /**
    * 
    * @param {Object} data 
    * data = {
    *   type: ["success","warning","error","info"]
    *   title: string
    *   message: string
    * }
    */
    displayToast(data) {
      this.$store.commit("toast/showToast", data);
    },
    formatRut(rut) {
      return formatRut(rut);
    },
    formatDate(date) {
      let result = date.split(' ');
      let newDate
      if (result.length == 2) {
          newDate = moment(date).format('DD-MM-YYYY HH:mm');
      } else {
          newDate = moment(date).format('DD-MM-YYYY');
      }
      return newDate
    }
  },
})

new Vue({
  router,
  vuetify,
  store: store,
  render: h => h(App)
}).$mount('#app')
