<template>
  <!-- App.vue -->
  <v-app>
    <v-app-bar color="#33AFFF" dense app>      
      <v-toolbar-title class="white--text">BENEFICIOS</v-toolbar-title>
      <modal-show-request v-if="this.currentRouteName!='success'"/>

    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>      
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <toast />
        <!-- If using vue-router -->
        <slot name="contenido"></slot>
      </v-container>
    </v-main>

    <v-footer padless class="mt-4" app style="margin-top: 3em">
      <v-col class="text-center" cols="12">
        {{ new Date().getFullYear() }} —
        <strong>Servicio de Salud Magallanes &copy; </strong>
        Versión {{ global }}
      </v-col>
    </v-footer>
  </v-app>
</template>
<script>
import Toast from '../components/customComponents/Toast.vue';
import ModalShowRequest from "@/components/requests/ModalShowRequest.vue";
export default {
  components: {ModalShowRequest, Toast},
  data() {
    return {
      drawer: false,
      global: process.env.VUE_APP_GLOBAL_VERSION,
    };

  },
  computed: {
    currentRouteName() {
      console.log(this.$route.name)
      return this.$route.name;
    }
  }
};
</script>