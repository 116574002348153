import { RequestService } from "../services/models/request";

const moduleRequest = {
    namespaced: true,
    state: {
        request: [],
        loading:false,
    },
    getters: {
        getRequest(state) {
            return state.request;
        },
        getLoading(state){
            return state.loading
        }
    },
    mutations: {
        setRequest(state, request) {
            state.request = request;
        },
        setLoading(state,loading){
            state.loading=loading
        }
    },
    actions: {
        async getRequest({ commit }, payload) {
            commit('setLoading',true)
            let requests;
            if (payload.search) {
                requests = await RequestService.getByFilter(payload.search, payload.page, payload.itemsPerPage);
            } else {
                requests = await RequestService.getPaginated(payload.page, payload.itemsPerPage);
            }
            let requestsData = requests.data.map(request => {
                return {
                    ...request,
                    completeApplicantName: `${request.name_applicant} ${request.first_lastname_applicant} ${request.second_lastname_applicant}`
                }
            });
            commit("setRequest", {
                ...requests,
                data: requestsData
            });
            commit('setLoading',false)
        },
        async getRange({commit},payload){
            commit('setLoading',true)
            let requests;
            if (payload.search != "") {
                requests = await RequestService.getByRangeDateFilter(payload.dateStart + " 00:00:00", payload.dateEnd + " 23:59:59", payload.search, payload.page, payload.itemsPerPage);
            } else {
                requests = await RequestService.getByRangeDate(payload.dateStart + " 00:00:00", payload.dateEnd + " 23:59:59", payload.page, payload.itemsPerPage);
            }
            let requestsData = requests.data.map(request => {
                return {
                    ...request,
                    completeApplicantName: `${request.name_applicant} ${request.first_lastname_applicant} ${request.second_lastname_applicant}`
                }
            });
            commit("setRequest", {
                ...requests,
                data: requestsData
            });
            commit('setLoading',false)
        }
    }
}

export default moduleRequest;