import Login from "./../views/Login.vue";
import Form from "../views/Form.vue";
import RequestFinish from "../views/RequestFinish.vue";

import {
    routes as administrativeRoutes
} from "./administrative";
import {
    routes as administratorRoutes
} from "./administrator";

export const routes = [{
        path: "/login",
        name: "Login",
        component: Login,
    },
    {
        path: "/",
        name: "Form",
        component: Form,
    },    
    {
        path: '*',
        name: "Login",
        component: Login,
    },
    {
        path: '/success',
        name: "success",
        component: RequestFinish,
    },
    ...administrativeRoutes,    
    ...administratorRoutes,    
];