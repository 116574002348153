import { UserService } from "../services/models/user";

const moduleUser = {
	namespaced: true,
	state: {
		user: []
	},
	getters: {
		getUser(state) {
			return state.user;
		}
	},
	mutations: {
		loadUser(state, user) {
			state.user = user;
		}
	},
	actions: {
		async getUser({commit}, userId) {
			let user = await UserService.getById(userId);
			commit("loadUser", user);
		}
	}
}

export default moduleUser;