<template>
  <section>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn
          class="mx-2"
          color="primary"
          elevation="2"
          style="margin: 1em"
          fab
          dark
          small
          v-on="on"
          :loading="isLoading"
          @click="reportPDF"
        >
          <v-icon dark> mdi-clipboard-account </v-icon>
        </v-btn>
      </template>
      <span>PDF de la solicitud</span>
    </v-tooltip>
  </section>
</template>
<script>
import { GeneratePdfRequest } from '../../others/ReportRequest';
export default {
    props:{
        request:{
            type:Object,
            required:true,
        }
    },
    data(){
        return{
            isLoading:false,
        }
    },
    methods:{
        async reportPDF(){
            this.isLoading=true;
            await GeneratePdfRequest(this.request.id)
            this.isLoading=false;
        }
    }
}
</script>