<template>
  <v-dialog v-model="dialog" max-width="800px" scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="mx-2"
        color="error"
        elevation="2"
        style="margin: 1em"
        fab
        dark
        small
        v-bind="attrs"
        v-on="on"
      >
        <v-icon dark> mdi-message-text-clock</v-icon>
      </v-btn>
    </template>

    <v-card :loading="isLoading" :disabled="isLoading">
      <v-card-title>Observación</v-card-title>
      <v-card-text>
        <v-alert type="success" dense prominent text>
          <ul>
            <li v-for="(item,index) in observations" :key="index">
              <b>{{item.created_at}}:</b> {{item.observations}}
            </li>
          </ul>          
        </v-alert>
      </v-card-text>
      <v-card-actions flat>
        <v-btn @click="dialog = false" color="secondary">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { HistoryService } from "../../services/models/History";
export default {
  props: {
    request: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
      observations: [],
      isLoading: false,
    };
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.show();
      }
    },
  },
  methods: {
    async show() {
      this.isLoading = true;
      let data = await HistoryService.finbByRequest(this.request.id);
      this.observations = data;
      this.isLoading = false;
    },
  },
};
</script>