<template>
  <!-- <v-row justify="center"> -->
  <v-dialog v-model="dialog" persistent max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="success" small dark v-bind="attrs" v-on="on">
        Validar
      </v-btn>
    </template>
    <v-card>
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card-title class="headline"
        >¿Está seguro/a que desea Validar la solicitud?</v-card-title
      >
      <v-card-text style="font-size: 1rem; margin-top: 1em"
        >Se dará como <b>Validada</b> la solicitud
        {{ request.request_type_detail.name }} del usuario
        <b
          >{{ request.name_applicant }}
          {{ request.first_lastname_applicant }}
          {{ request.second_lastname_applicant }} ({{ request.rut }})</b
        >.
        <v-row class="mt-4">
          <v-col cols="12">
            <v-textarea
              name="input-7-1"
              label="Observaciones"
              hint="Ingrese observaciones si estima conveniente"
              v-model="observations"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="dialog = false"
          >Cancelar</v-btn
        >
        <v-btn color="green darken-1" text @click="finishRequest"
          >Aceptar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- </v-row> -->
</template>

<script>
import { RequestService } from "../../../services/models/request";
import { HistoryService } from "../../../services/models/History";
import { mapActions } from "vuex";
export default {
  props: ["request"],
  data() {
    return {
      dialog: false,
      loading: false,
      observations: null,
    };
  },
  methods: {
    ...mapActions({
      setRequest: "requests/getRequest",
    }),
    async sendMail(email, message) {
      let data = {
        email: email,
        message: message,
      };
      await RequestService.sendEmail(data);
    },
    async finishRequest() {
      this.loading = true;
      try {
        let currentRequest = await RequestService.getById(this.request.id);
        if (currentRequest.state != "pendiente") {
          this.displayToast({
            type: "warning",
            message:
              "La solicitud no se puede procesar ya que no se encuentra en estado pendiente",
          });
          this.dialog = false;
          this.loading = false;
          this.setRequest();
          return;
        }
        await RequestService.update(this.request.id, {
          ...currentRequest,
          state: "validada",
        });
        if (this.observations) {
          await HistoryService.create({
            user_id: this.getCurrentUserLoggedIn().id,
            request_id: this.request.id,
            observations: this.observations,
          });
        }
        this.displayToast({
          type: "success",
          message: "La solicitud se ha validado con éxito",
        });
        let message = `
          <div>
           <h2>Estimado/a</h2><br>          
             Su Solicitud fue validada ${this.observations?`con la siguiente observación:<br> <b>${this.observations}</b>`:'sin problemas'}                 
         </div>
         `;
        this.sendMail(this.request.email, message);
        this.setRequest();
      } catch (error) {
        this.displayToast({
          type: "error",
          message: "Ha ocurrido un error al finalizar la solicitud",
        });
        console.log(error);
      }
      this.loading = false;
      this.dialog = false;
    },
  },
};
</script>