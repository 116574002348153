
<script src="https://gist.github.com/awunder/5ea26d7b7c6b1e3cf29ab265070478cb.js"></script>

<template>
  <div id="kk">
    <v-container id="signinup-form" class="fill-height">
      <v-row align="center" justify="center" no-gutters>
        <v-col cols="12" sm="8" md="5" class="">
          <v-card class="evelation-12 card" :loading="loading" shaped>
            <v-window>
              <v-window-item :value="1">
                <v-card-text>
                  <v-form class="signup-form-form" @submit.prevent="connect">
                    <v-row>
                      <v-col cols="4">
                        <v-avatar size="83">
                          <v-img
                            contain
                            max-height="97"
                            max-width="105"
                            src="/icono.png"
                          ></v-img>
                        </v-avatar>
                      </v-col>
                      <v-col cols="7">
                        <h1
                          class="text-center display-1"
                          :class="`${bgColor}--text`"
                        >
                          BENEFICIOS
                        </h1>
                      </v-col>
                    </v-row>
                    <v-text-field
                      v-model="email"
                      label="Correo"
                      type="text"
                      :color="bgColor"
                      :loading="loading"
                    />
                    <v-text-field
                      v-model="password"
                      label="Contraseña"
                      type="password"
                      :color="bgColor"
                      :loading="loading"
                    />
                    <div class="text-center mt-6">
                      <v-btn
                        type="submit"
                        large
                        :color="bgColor"
                        dark
                        :loading="loading"
                        ><v-icon left dark> mdi-login </v-icon>Ingresar</v-btn
                      >
                    </div>
                  </v-form>
                </v-card-text>
              </v-window-item>
            </v-window>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { UserService } from "../services/models/user";

export default {
  name: "Signupform",
  props: {
    source: {
      type: String,
      default: "",
    },
    bgColor: {
      type: String,
      default: "#0FCBBF",
    },
    fgColor: {
      type: String,
      default: "white",
    },
  },
  data: () => ({
    email: "",
    password: "",
    loading: false,
  }),
  beforeCreate: function () {
    document.body.className = "intro";
  },
  methods: {
    async connect() {
      this.loading = true;
      let data = {
        email: this.email,
        password: this.password,
        grant_type: "password",
      };
      try {
        let response = await UserService.login(data);        
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        switch (response.data.user.role_id) {
          case 1: //Administrator
            this.$router.push({ name: "administratorHome" });
            break;
          case 2: //Control de Gestion
            this.$router.push({ name: "manegementControlHome" });
            break;
          case 3: //Administrative
            this.$router.push({ name: "administrativeHome" });
            break;

          default:
            break;
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
#kk {
  height: 100%;
  background: url("/fondo.jpg") no-repeat center center fixed !important;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
}
.v-input__icon--double .v-input__icon {
  margin-left: -4.25rem !important;
}
a.no-text-decoration {
  text-decoration: none;
}
#signinup-form {
  max-width: 75rem;
}
.signup-form-form {
  max-width: 23rem;
  margin: 0 auto;
}
.card {
  overflow: hidden;
}
.vcenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
