import EmptyRouterView from './../views/administrator/EmptyRouterView.vue';
import Administrator from './../views/administrator/Administrator.vue';
import Requests from './../views/administrator/requests/Requests';
import Users from './../views/administrator/mainteiners/user/User.vue';

export const routes = [
  { path: '/administrator',
    component: EmptyRouterView,
    children: [
      {
        path: 'inicio',
        name: 'administratorHome', 
        component: Administrator,
      },
      {
        path: 'solicitudes',
        name: 'administratorRequests', 
        component: Requests,
      },
      {
        path: 'usuarios',
        name: 'administratorUsers', 
        component: Users,
      },
    ]
  },
]