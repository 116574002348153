const moduleToast = {
    namespaced: true,
    state: {
		showToast: false,
		toastData: {
			type: null,
			title: null,
			message: null,
			milliseconds: 5000
		}
	},
	getters: {
		getShowToast(state) {
			return state.showToast;
		}
	},
	mutations: {
		showToast(state, payload) {
			console.log(payload);
			state.toastData.type = payload.type;
			state.toastData.title = payload.title;
			state.toastData.message = payload.message;
			state.toastData.milliseconds = payload.milliseconds || state.toastData.milliseconds;
			if (payload.type == "success") {
				state.toastData.title = payload.title || "Éxito";
			}
			else if (payload.type == "error") {
				state.toastData.title = payload.title || "Error";
			}
			else if (payload.type == "warning") {
				state.toastData.title = payload.title || "Validación";
			}
			state.showToast = true;
		},
	}
}

export default moduleToast;