import { BaseService } from './../base'
export class RequestTypeService extends BaseService {
    static get entity () {
        return 'request_type'
    }
    static get name () {
        return 'request_type'
    }
    static async getAll() {
        return this.submit('get', `${process.env.VUE_APP_API_URL}/api/${this.entity}`, this.name);        
    }
}