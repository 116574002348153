<template>
  <PlantillaHome>
    <div slot="contenido">
      <v-card elevation="10" class="d-flex pa-4">
        <div style="width: 100%">
          <v-data-table
            :headers="headers"
            :items="setRequest.data"
            :page.sync="setRequest.current_page"
            :items-per-page="itemsPerPage"
            :server-items-length="setRequest.total"
            item-key="id"
            class="elevation-1"
            :loading="setLoading"
            show-expand
            :expanded.sync="expanded"
            @update:page="changePage"
            :disable-sort="true"
            @update:items-per-page="changeItemPerPage"
            loading-text="Cargando... Favor de esperar"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Solicitudes</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-text-field
                  label="Fecha de inicio"
                  hide-details="auto"
                  type="date"
                  v-model="dateStart"
                  class="mr-4"
                ></v-text-field>
                <v-text-field
                  label="Fecha de término"
                  hide-details="auto"
                  type="date"
                  v-model="dateEnd"
                  class="mr-4"
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-btn color="primary" class="mr-4" @click="searchButton"
                  >buscar</v-btn
                >
                <v-btn color="error" class="mr-4" @click="cleanSearch"
                  ><v-icon>mdi-refresh</v-icon></v-btn
                >
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn 
                      color="info" 
                      @click="downloadReport"
                      v-on="on"
                      :loading="isReportLoading"
                      ><v-icon>mdi-file</v-icon></v-btn
                    >
                  </template>
                  <span>Descargar reporte solicitudes</span>
                </v-tooltip>
              </v-toolbar>
            </template>
            <template v-slot:[`item.rut`]="{ item }">
              <v-row align="center">
                <span>{{ formatRut(item.rut) }}</span>
              </v-row>
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
              <v-row align="center">
                <span>{{ formatDate(item.created_at) }}</span>
              </v-row>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-row align="center">
                <v-col cols="8">
                  <div v-if="item.state == 'pendiente'">
                    <v-col>
                      <validate-request :request="item" />
                    </v-col>
                    <v-col>
                      <btn-observation :request="item" />
                    </v-col>
                  </div>
                  <div v-if="item.state == 'validada'">
                    <finish-request :request="item" />
                  </div>
                </v-col>
                <v-col cols="2">
                  <btn-report :request="item" />
                </v-col>
                <v-col cols="2">
                  <btn-show-observation
                    :request="item"
                    v-if="
                      item.histories.length != 0
                        ? item.histories[0].observations
                        : false
                    "
                  />
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.completeApplicantName`]="{ item }">
              {{ item.completeApplicantName }}
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-row style="padding: 2em">
                  <v-col cols="12" sm="6">
                    <v-card class="mx-auto" elevation="4">
                      <v-card-title
                        style="background-color: #33afff; color: white"
                      >
                        <v-icon color="white" large left> mdi-account </v-icon>
                        <span class="title font-weight-light"
                          >{{ item.name_applicant }}
                          {{ item.first_lastname_applicant }}
                          {{ item.second_lastname_applicant }}</span
                        >
                      </v-card-title>
                      <v-list-item three-line>
                        <v-list-item-content>
                          <v-list-item-subtitle>
                            <v-row
                              style="margin-top: 0.5em; margin-bottom: 0.5em"
                            >
                              <v-col cols="12" sm="6">
                                <b>Rut:</b> {{ item.rut }}
                              </v-col>
                              <v-col cols="12" sm="6">
                                <b>Correo electrónico:</b>
                                {{ item.email || "Sin información" }}
                              </v-col>
                              <v-col cols="12" sm="6">
                                <b>Celular</b>
                                {{
                                  item.cellphone_applicant || "Sin información"
                                }}
                              </v-col>
                              <v-col cols="12" sm="6">
                                <b>Grado:</b>
                                {{ item.grade || "Sin información" }}
                              </v-col>
                              <v-col cols="12" sm="6">
                                <b>Posición:</b>
                                {{ item.position || "Sin información" }}
                              </v-col>
                              <v-col cols="12" sm="6">
                                <b>Servicio:</b>
                                {{
                                  item.services_applicant || "Sin información"
                                }}
                              </v-col>
                              <v-col cols="12" sm="6">
                                <b>Unidad:</b>
                                {{
                                  item.unities_applicant || "Sin información"
                                }}
                              </v-col>
                              <v-col cols="12" sm="6">
                                <b>Establecimiento:</b>
                                {{
                                  item.stablishment
                                    ? item.stablishment.name
                                    : "Sin información"
                                }}
                              </v-col>
                            </v-row>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-card style="height: 100%" class="mx-auto" elevation="4">
                      <v-card-title
                        style="background-color: #33afff; color: white"
                      >
                        <v-icon color="white" large left>
                          mdi-file-document
                        </v-icon>
                        <span class="title font-weight-light"
                          >Datos de la solicitud</span
                        >
                      </v-card-title>
                      <v-list-item three-line>
                        <v-list-item-content>
                          <v-list-item-subtitle>
                            <v-row
                              style="margin-top: 0.5em; margin-bottom: 0.5em"
                            >
                              <v-col cols="12" sm="6">
                                <b>Tipo de solicitud:</b>
                                {{ item.request_type.name }}
                              </v-col>
                              <v-col cols="12" sm="6">
                                <b>Tipo de solicitud:</b>
                                {{ item.request_type_detail.name }}
                              </v-col>
                              <v-col cols="12" sm="6">
                                <b>Fecha:</b> {{ item.created_at }}
                              </v-col>
                              <v-col cols="12" sm="6">
                                <b>Estado:</b> {{ item.state }}
                              </v-col>
                              <v-col cols="12" sm="6">
                                <b>Responsable:</b>
                                {{
                                  item.user
                                    ? `${item.user.name} ${item.user.pather_lastname} ${item.user.mother_lastname}`
                                    : "Sin responsable"
                                }}
                              </v-col>


                              <v-col cols="12" sm="6">
                                <b>Codigo: </b>
                                {{
                                  item.code
                                }}
                              </v-col>
                            </v-row>
                            <modal-show-files :requestForm="item" />
                            <v-btn
                              v-if="item.document_identification"
                              elevation="2"
                              style="margin-left: 1em"
                              color="success"
                              @click="openDocument(item.document_identification)"
                            >Carnet</v-btn> 
                            <modal-show-files-salaries 
                                  :requestForm="item" 
                                  v-if="item.request_type.id==3" 
                            />
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </v-col>
                </v-row>
              </td>
            </template>
          </v-data-table>
        </div>
      </v-card>
    </div>
  </PlantillaHome>
</template>
<script>
import PlantillaHome from "./../../../templates/PlantillaHome.vue";
import FinishRequest from "./FinishRequest.vue";
import BtnReport from "../../../components/requests/BtnReport.vue";
import BtnObservation from "../../../components/requests/BtnObservation.vue";
import { mapActions, mapGetters } from "vuex";
import BtnShowObservation from "../../../components/requests/BtnShowObservation.vue";
import ModalShowFiles from "../../../components/requests/ModalShowFiles.vue";
import ModalShowFilesSalaries from "../../../components/requests/ModalShowFilesSalaries.vue";

import moment from "moment";
import ValidateRequest from "./ValidateRequest.vue";
import { RequestService } from '../../../services/models/request';
export default {
  components: {
    PlantillaHome,
    ModalShowFiles,
    FinishRequest,
    BtnReport,
    BtnObservation,
    BtnShowObservation,
    ValidateRequest,
    ModalShowFilesSalaries
  },
  data() {
    return {
      search: "",
      listRequests: [],
      loading: false,
      expanded: [],
      dateStart: null,
      dateEnd: null,
      page: 1,
      itemsPerPage: 15,
      isReportLoading: false,
      headers: [
        {
          text: "Folio",
          value: "id",
          align: "start",
        },
        {
          text: "Tipo de solicitud",
          value: "request_type_detail.name",
          align: "start",
        },
        {
          text: "Nombre solicitante",
          value: "completeApplicantName",
          align: "start",
        },
        {
          text: "Rut solicitante",
          value: "rut",
          align: "start",
        },
        { text: "Fecha", value: "created_at", align: "start", sortable: false },
        { text: "Estado", value: "state", align: "start" },
        { text: "Acciones", value: "actions" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      setRequest: "requests/getRequest",
      setLoading: "requests/getLoading",
    }),
  },
  methods: {
    changeItemPerPage(items) {
      this.itemsPerPage = items == -1 ? this.setRequest.total : items;
      this.changePage();
    },
    ...mapActions({
      getRequest: "requests/getRequest",
      getRange: "requests/getRange",
    }),
    async downloadReport() {
      try {
        this.isReportLoading = true;
        let response = await RequestService.getRequestsReport({
          date_start: this.dateStart,
          date_end: this.dateEnd,
          param: this.search
        });
        let a = document.createElement("a");
        let url = window.URL.createObjectURL(response);
        a.href = url;
        a.download = `REPORTE_SOLICITUDES.xlsx`;
        document.body.append(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("ERROR!!!", error);
      } finally {
        this.isReportLoading = false;
      }
    },
    openDocument(url) {
      window.open(url, '_blank').focus();
    },
    cleanSearch() {
      this.dateStart = null;
      this.dateEnd = null;
      this.page = 1;
      this.itemsPerPage = 15;
      this.search = "";
      this.getRequest(this.page);
    },
    searchButton() {
      this.changePage();
    },
    changePage(page) {
      this.page = page;
      if (this.dateStart || this.dateEnd) {
        this.searchByRange();
      } else {
        this.getRequest({
          page: this.page,
          search: this.search,
          itemsPerPage: this.itemsPerPage,
        });
      }
      // else if (!this.dateStart && !this.dateEnd && this.search != "") {
      //   this.getRequest({
      //     page: this.page,
      //     search: this.search,
      //   });
      // } else if (!this.dateStart && !this.dateEnd && this.search == "") {
      //   this.getRequest({
      //     page: this.page
      //   });
      // }
    },
    async searchByRange() {
      if (!this.dateStart || !this.dateEnd) {
        this.displayToast({
          type: "warning",
          message: "Por favor seleccione fecha de inicio y término",
        });
        return;
      }
      this.loading = true;
      try {
        const dateStart = moment(this.dateStart).format("YYYY-MM-DD");
        const dateEnd = moment(this.dateEnd).format("YYYY-MM-DD");
        this.getRange({
          dateStart,
          dateEnd,
          search: this.search,
          page: this.page,
          itemsPerPage: this.itemsPerPage
        });
      } catch (error) {
        this.displayToast({
          type: "error",
          message: "Ha ocurrido un error al cargar las solicitudes",
        });
      }
      this.loading = false;
    },
  },
  mounted() {
    this.getRequest(this.page);
  },
};
</script>