<template>
  <v-alert
    :value="show"
    transition="scale-transition"
    :type="color"
		elevation="8"
		colored-border
		border="bottom"
    style="position: absolute; top: 2em; right: 2em; z-index: 999999"
  >
    {{text}}
  </v-alert>
</template>
<script>
import { eventBus } from "../../main";
export default {
  data() {
    return {
      show: false,
      text: "",
      color: "success",
			time: 3000
    };
  },
  mounted() {
    eventBus.$on("showNotification", (options) => {
      this.text = options.text;
      this.color = options.color;
			this.time = options.time ? options.time : this.time;
      this.show = true;
			setTimeout(() => {
				this.show = false;
			}, this.time);
    });
  },
};
</script>