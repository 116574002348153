var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"800px","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"color":"white"},attrs:{"color":"teal","outlined":"","disabled":_vm.requestForm.files.length == 0},on:{"click":_vm.mostrarModal}},'v-btn',attrs,false),on),[_c('v-icon',{staticStyle:{"margin-right":"0.5em"},attrs:{"dark":""}},[_vm._v(" mdi-file-multiple ")]),_vm._v(" Adjuntos ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Archivos adjuntos")]),_c('v-card-text',{staticStyle:{"height":"100%"}},[_c('v-overlay',{attrs:{"value":_vm.loading,"absolute":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.requestForm.files,"item-key":"created_at","loading":_vm.loading,"loading-text":"Cargando... Favor de esperar"},scopedSlots:_vm._u([{key:"item.file_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.file_name)+" ")]}},{key:"item.file",fn:function(ref){
var item = ref.item;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"color":"white"},attrs:{"color":"teal","outlined":""},on:{"click":function () { return _vm.openFile(item.file); }}},'v-btn',_vm.attrs,false),_vm.on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-eye ")])],1)]}}],null,true)})],1),_c('v-card-actions',{attrs:{"flat":""}},[_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Cerrar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }