import { BaseService } from './../base'
export class HistoryService extends BaseService {
    static get entity () {
        return 'history'
    }
    static get name () {
        return 'history'
    }
    static async finbByRequest(id) {
        return this.submit('get', `${process.env.VUE_APP_API_URL}/api/${this.profile}/${this.entity}/find_by_request/${id}`, this.name);        
    }
}