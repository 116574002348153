import moment from "moment";
import { RequestService } from "../services/models/request";
import { formatRut } from "../thirdParty/utils";
import logoSsm from "./logoSsm";

export const GeneratePdfRequest = async (request_id) => {
    let responseRequest = await RequestService.getById(request_id);
    var pdfMake = require("pdfmake/build/pdfmake.js");
    if (pdfMake.vfs == undefined) {
        var pdfFonts = require("pdfmake/build/vfs_fonts.js");
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
    }
    var docDefinition = {
        pageMargins: [40, 30, 40, 60],
        content: [
            {
                columns: [
                    {
                        image: logoSsm,
                        width: 70,
                    },
                    [
                        {
                            text: `FOLIO N°${responseRequest.id}`,
                            style: 'titleHeader',
                            margin: [0, 10, 0, 0]
                        },
                    ],
                    {
                        text: `Rut: ${formatRut(responseRequest.rut)}`,
                        style: 'subtitleHeader',
                        width: 'auto',
                        margin: [0, 10, 0, 0]
                    }
                ]
            },
            {
                margin: [0, 25, 0, 0],
                table: {
                    widths: [120, 360],
                    body: [
                        [
                            { text: 'FECHA SOLICITUD', style: 'tableHeader' },
                            { text: `${moment(responseRequest.created_at).format("DD-MM-YYYY")}` },                            
                        ],
                        [
                            { text: 'TIPO DE SOLICITUD', style: 'tableHeader' },
                            { text: `${responseRequest.request_type.name}` },                            
                        ],
                        [
                            { text: 'SUB TIPO', style: 'tableHeader' },
                            { text: `${responseRequest.request_type_detail.name}` },
                        ],
                        [
                            { text: 'NOMBRE', style: 'tableHeader' },
                            { text: `${responseRequest.name_applicant}` },                            
                        ],
                        [
                            { text: 'APELLIDO PATERNO', style: 'tableHeader' },
                            { text: `${responseRequest.first_lastname_applicant}` },
                        ],
                        [
                            { text: 'APELLIDO MATERNO', style: 'tableHeader' },
                            { text: `${responseRequest.second_lastname_applicant}` },                            
                        ],
                        [
                            { text: 'RUN', style: 'tableHeader' },
                            { text: `${formatRut(responseRequest.rut)}` },
                        ],
                        [
                            { text: 'EMAIL', style: 'tableHeader' },
                            { text: `${responseRequest.email}` },                            
                        ],
                        [
                            { text: 'CELULAR', style: 'tableHeader' },
                            { text: `${responseRequest.cellphone_applicant}` }
                        ],
                        [
                            { text: 'ESTABLECIMIENTO', style: 'tableHeader' },
                            { text: `${responseRequest.stablishment?.name || 'Sin Establecimiento'}` },                            
                        ],
                        [
                            { text: 'UNIDAD/CR', style: 'tableHeader' },
                            { text: `${responseRequest.unities_applicant || 'Sin Unidad/CR'}` },
                        ],
                        [
                            { text: 'SERVICIO', style: 'tableHeader' },
                            { text: `${responseRequest.services_applicant || 'Sin Servicio'}` },                           
                        ],
                        [
                            { text: 'CARGO', style: 'tableHeader' },
                            { text: `${responseRequest.position || 'Sin Cargo'}` },
                        ],
                        [
                            { text: 'GRADO', style: 'tableHeader' },
                            { text: `${responseRequest.grade || 'Sin Grado'}`}
                        ],
                        [
                            { text: 'RESPONSABLE', style: 'tableHeader' },
                            { text: `${responseRequest.user?.name || 'Sin Responsable'} ${responseRequest.user?.pather_lastname || 'Sin Responsable'} ${responseRequest.user?.mother_lastname || 'Sin Responsable'}
                            `}
                        ],
                        [
                            { text: 'ESTADO', style: 'tableHeader' },
                            { text: `${responseRequest.state}`}
                        ],
                        [
                            { text: 'OBSERVACIÓN', style: 'tableHeader' },
                            { text: `${responseRequest.histories[0]?.observations || 'Sin Observación'}`}
                        ],
                    ]
                }
            },
            {
                margin: [0, 20, 0, 0],
                alignment: "justify",
                text: `Contemplado en el Reglamento de Bienestar Decreto Supremo Nº 29 de 1997, y para lo cual declaro ser imponente y estar al día en el pago de mis imposiciones. Tomo conocimiento que la Unidad de Bienestar hará efectivo el pago correspondiente, de acuerdo a la modalidad de recibo de remuneraciones que tengo con el Servicio de Salud (depósito bancario – Pago Cash). Como afiliado al S. de Bienestar declaro dar fe que los antecedentes presentados adjuntos a esta solicitud son fidedignos y tomo conocimiento que: el S. de Bienestar puede verificar la validez de éstos y que se encuentra contemplado en el D.S Nº 28: “al obtener beneficios económicos valiéndose de documentos o datos falsos, éstos deberán ser reembolsados reajustados en un 100% del interés respectivo dentro de los límites establecidos por la Ley Nº 18.010”.`
            }
        ],
        styles: {
            tableHeader: {
                bold: true,
                fontSize: 11,
                color: 'black',
                fillColor: '#e4e4e4'
            },
            tableExample: {
                alignment: "justify",
                margin: [0, -150, 0, 15],
            },
            toTheRight: {
                alignment: "right",
            },
            header: {
                fontSize: 17,
                alignment: "left",
                bold: true,
            },
            titleHeader: {
                fontSize: 18,
                bold: true,
                alignment: 'center'
            },
            subtitleHeader: {
                fontSize: 14,
                alignment: 'center'
            }
        },
    };
    pdfMake.createPdf(docDefinition).download("Reporte.pdf");
}
