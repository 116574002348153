<template>
  <PlantillaPublic>
    <div slot="contenido">
      <v-card>
        <v-card-title> Solicitud Enviada </v-card-title>
        <v-card-subtitle> Su solicitud fue enviada sin problemas</v-card-subtitle>
        <v-card-text> 
            <v-icon            
                x-large
                color="success"
                >mdi-check-decagram</v-icon>
                LA SOLICITUD N° {{request.code}} YA SE ENCUENTRA EN PROCESO, PRESIONE EL BOTON QUE SE VE A CONTINUACIÓN:
                <ModalShowRequest :code=request.code />

        </v-card-text>
         <v-card-actions>
          <v-btn
            color="info"
            dark
            right
            absolute            
            @click.native="redirect"
          >
            Volver
          </v-btn>

        </v-card-actions>
      </v-card>
    </div>
  </PlantillaPublic>
</template>
<script>
import PlantillaPublic from "../templates/PlantillaPublic.vue";
import ModalShowRequest from "@/components/requests/ModalShowRequest.vue";
export default {
  components: {
    ModalShowRequest,
    PlantillaPublic,

  },
  data() {
    return {
      request: this.$route.params.responseRequest
    };
  },
  mounted() {
    console.log('este es el parametro:' + this.$route.params.responseRequest)
  },
  methods: {
      redirect(){
          this.$router.push({ name: "Form" })
      },

  },
};
</script>