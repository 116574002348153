import EmptyRouterView from './../views/administrative/EmptyRouterView.vue';
import Administrative from './../views/administrative/Administrative.vue'

export const routes = [
  { path: '/administrativo',
    component: EmptyRouterView,
    children: [
      {
        path: 'inicio',
        name: 'administrativeHome', 
        component: Administrative,
      },        
    ]
  },
]