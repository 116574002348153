<template>
  <v-dialog v-model="dialog" max-width="800px" scrollable>
    <template v-slot:activator="{ on }">
      <v-btn
          color="success"
          dark
          right
          absolute
          v-on="on"
          @click="mostrarModal"
      >
        Ver Solicitud
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Datos de Solicitud</v-card-title>
      <v-card-text  >
        <v-text-field
            label="Codigo de Solicitud"
            v-model="request"
            required
        />
        </v-card-text>
      <v-card-text style="height: 100%" v-if="requestForm">

        <v-row>
          <v-col
              cols="12"
              sm="6"
          >
            <v-text-field
                label="Nombre"
                v-model="requestForm.name_applicant"
                readonly
            />
          </v-col>
          <v-col
              cols="12"
              sm="6"
          >
            <v-text-field

                label="Apellido"
                v-model="requestForm.first_lastname_applicant"
                readonly
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
              cols="12"
              sm="6"
          >
            <v-text-field
                label="Servicio"
                v-model="requestForm.services_applicant"
                readonly
            />
          </v-col>
          <v-col
              cols="12"
              sm="6"
          >
            <v-text-field
                label="Tipo de solicitud"
                v-model="requestForm.request_type.name"
                readonly
            />
          </v-col>
        </v-row>
        <v-text-field
            label="Estado"
            v-model="requestForm.state"
            readonly
            background-color="#FDFD92"
        />
        <v-row>
          <v-col
              cols="12"
              sm="10"
          >
            <v-text-field
                v-model="requestForm.document_identification_name"
                label="Cédula de identidad"
                readonly>
            </v-text-field>
          </v-col>
        <v-col
            cols="12"
            sm="2">
          <v-btn
              color="teal"
              style="color: white"
              outlined
              v-bind="attrs"
              v-on="on"
              @click="() => openFile(requestForm.document_identification)"
          > <v-icon dark>
            mdi-eye
          </v-icon></v-btn>
        </v-col>

        </v-row>


        <v-card-title>Archivos Adjunto</v-card-title>
        <v-data-table v-if="requestForm"
            :headers="headers"
            :items="requestForm.files"
            item-key="created_at"
            class="elevation-2"
            :loading="loading"
            loading-text="Cargando... Favor de esperar"
        >
          <!-- <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Historial de modificaciones</v-toolbar-title>
            </v-toolbar>
          </template> -->
          <template v-slot:[`item.file_name`]="{ item }">
            {{ item.file_name }}
          </template>
          <template v-slot:[`item.file`]="{ item }">
            <v-btn
                color="teal"
                style="color: white"
                outlined
                v-bind="attrs"
                v-on="on"
                @click="() => openFile(item.file)"
            >

              <v-icon dark>
                mdi-eye
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <v-overlay :value="loading" absolute>
          <v-progress-circular indeterminate size="64"></v-progress-circular>

        </v-overlay>
      </v-card-text>

      <v-card-actions flat>
        <v-btn @click="dialog = false" color="secondary">Cerrar</v-btn>
        <v-btn @click="searchCode" color="info" right absolute>Buscar Solicitud</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {RequestService} from "../../services/models/request.js"
export default {
  props: ["code"],
  data() {
    return {
      dialog: false,
      loading: false,
      request:this.code!=undefined? this.code:"",
      requestForm:null,
      headers: [
        { text: "Nombre documento", value: "file_name" },
        { text: "Archivo", value: "file" },
      ],
    };
  },

  mounted() {

  },
  methods: {
    async mostrarModal() {
      this.dialog = true;
      if (this.code!=undefined){
        this.searchCode();
      }
    },
    async searchCode(){
      this.loading=true
      let requestCode = await RequestService.getByRequestCode(this.request)
      console.log(requestCode)
      this.requestForm = requestCode[0]
      this.loading=false
    },
    openFile(file) {
      window.open(file,'_blank');
    }
  },
  //   watch: {
  //     dialog() {
  //       if (this.dialog) {
  //         this.loadData();
  //       }
  //     },
  //   },
};
</script>