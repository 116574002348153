<template>
  <v-dialog v-model="dialog" max-width="800px" scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="orange" small dark v-bind="attrs" v-on="on">
        Validar con observacion
      </v-btn>
    </template>

    <v-card :loading="isLoading" :disabled="isLoading">
      <v-card-title class="headline">
        ¿Está seguro/a que desea Validar la solicitud?
      </v-card-title>      
      <v-card-text>
        Se dará como <b>Validada con observación</b> la solicitud
      {{ request.request_type_detail.name }} del usuario
      <b
        >{{ request.name_applicant }}
        {{ request.first_lastname_applicant }}
        {{ request.second_lastname_applicant }} ({{ request.rut }})</b
      ><br><br>
        <v-textarea
          solo
          name="input-7-1"
          label="Favor de agregar observación"
          v-model="observation"
        ></v-textarea>
      </v-card-text>
      <v-card-actions flat>
        <v-btn @click="dialog = false" color="secondary">Cerrar</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="save" color="primary">Guardar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
import { HistoryService } from "../../services/models/History";
import { RequestService } from "../../services/models/request";
export default {
  props: {
    request: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
      observation: "",
      isLoading: false,
    };
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.observation = "";
      }
    },
  },
  methods: {
    ...mapActions({
      setRequest: "requests/getRequest",
    }),
    async sendMail(email, message) {
      let data = {
        email: email,
        message: message,
      };
      await RequestService.sendEmail(data);
    },
    async save() {
      if (this.observation != "") {
        this.isLoading = true;
        let resp = await RequestService.getById(this.request.id);
        let dataPut = {
          ...resp,
          user_id: this.getCurrentUserLoggedIn().id,
          state: "validada con observación",
        };
        await RequestService.update(resp.id, dataPut);
        let data = {
          request_id: this.request.id,
          user_id: this.getCurrentUserLoggedIn().id,
          observations: this.observation,
        };
        await HistoryService.create(data);
        let message = `
          <div>
           <h2>Estimado/a</h2><br>          
             Su Solicitud fue finalizada con la siguiente observación: <br> 
             <b>${this.observation}</b>
         </div>
         `;
        this.sendMail(this.request.email, message);
        this.setRequest();
        this.isLoading = false;
        this.dialog = false;
      } else {
        this.displayToast({
          type: "error",
          message: "Favor de agregar alguna observación",
        });
      }
    },
  },
};
</script>