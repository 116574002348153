import { BaseService } from './../base'
export class RequestFileService extends BaseService {
    static get entity () {
        return 'file'
    }
    static get name () {
        return 'request_file'
    }
    static async create(data,config) {
        return this.submit('post', `${process.env.VUE_APP_API_URL}/api/${this.entity}`, this.name,data,config);        
    }
}